@use "@angular/material" as mat;

button {
    cursor: pointer;
}

.primary-button,
.primary-button-warn,
.secondary-button,
.secondary-button-warn,
.secondary-button-gray,
.search-dd-button {
    background-color: var(--primary-color) !important;
    color: white !important;
    border-radius: 4px !important;
    font-size: 86% !important;
    min-width: 96px !important;
    text-transform: uppercase;
    box-shadow: 0 0 2px 0 rgba(141, 141, 148, 0.16), 0 2px 6px -4px rgba(141, 141, 148, 0.48) !important;
    line-height: 1.2em !important;
    letter-spacing: 0.5px !important;
    height: 32px !important;
    outline: transparent !important;
    &.h6-btn {
        font-size: 0.75rem !important; //12px
        line-height: 1rem !important; //16px
        letter-spacing: 0.0375rem !important;
        text-transform: uppercase !important;
    }
}

.secondary-button,
.secondary-button-warn,
.secondary-button-gray,
.search-dd-button {
    background-color: white !important;
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}

.secondary-button-warn {
    color: var(--warning-color) !important;
    border: 1px solid var(--warning-color) !important;
}

.secondary-button-gray,
.search-dd-button {
    color: var(--color-gray) !important;
    border: 1px solid var(--color-gray) !important;
}

.primary-button:hover,
.primary-button-warn:hover,
.secondary-button:hover,
.secondary-button-warn:hover,
.secondary-button-gray:hover,
.search-dd-button:hover {
    box-shadow: 1px 1px 6px rgba(141, 141, 148, 0.45) !important;
    opacity: 0.85;
}

.secondary-button-gray:hover,
.search-dd-button:hover {
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}

.primary-button:disabled,
.primary-button-warn:disabled,
.secondary-button:disabled,
.secondary-button-warn:disabled,
.secondary-button-gray:disabled,
.search-dd-button:disabled {
    background: #f6f6f6 !important;
    color: #babbc2 !important;
    box-shadow: 0 0 2px 0 rgba(141, 141, 148, 0.16), 0 2px 6px -4px rgba(141, 141, 148, 0.48) !important;
    opacity: 1;
}

.secondary-button:disabled,
.secondary-button-warn:disabled,
.secondary-button-gray:disabled,
.search-dd-button:disabled {
    border-color: #babbc2 !important;
}

.tertiary-button {
    font-size: 0.75rem !important; //12px
    line-height: 1rem !important; //16px
    letter-spacing: 0.0375rem !important;
    color: var(--primary-color) !important;
    border: none !important;
    background-color: transparent !important;
    display: flex;
    align-items: center;
    outline: transparent !important;
}

.tertiary-button mat-icon {
    opacity: 1 !important;
    font-size: 16px !important;
    height: 18px;
    margin-left: 2px;
}

.tertiary-button:hover {
    background: transparent !important;
    opacity: 0.75;
}

.tertiary-button:disabled {
    color: #babbc2 !important;
}

.btn-success {
  background: var(--success-color-dark) !important;
  color: var(--color-white) !important;
}

.btn-danger {
  background: var(--warning-color-dark) !important;
  color: var(--color-white) !important;
}
